import { computed } from '@vue/composition-api'
import {
  map,
  groupBy,
  chain,
  each,
  get,
} from 'lodash'
import { CashRegisterTransactionHeaders, CashRegisterTransactionHeadersByType } from '@/utils/constants/table-headers'

export default cashRegisterTransactions => {
  const items = computed(() => {
    return map(cashRegisterTransactions.value, item => {
      let from = ''
      if (item.attributes.receiptId) {
        from = `伝票 No. ${item.attributes.receiptId}`
      } else if (item.attributes.benefitId) {
        from = item.meta.benefitLabelText
      } else if (item.attributes.expenseId) {
        from = item.meta.expenseLabelText
      } else if (item.attributes.balanceReceivableId) {
        from = item.meta.balanceReceivableText
      } else if (item.attributes.realMoneyProfitId) {
        from = '預金'
      } else if (item.attributes.note) {
        from = item.attributes.note
      }

      const txTypeText = { deposit: '入金', withdraw: '出金' }[item.attributes.transactionType]

      return { ...item, from, txTypeText }
    })
  })

  const cashRegisterTransactionsByType = computed(() => {
    return groupBy(items.value, 'attributes.transactionType')
  })

  const buildAllTransactionsXlsxData = () => {
    return chain(items.value)
      .map(o => {
        const data = {}

        each(CashRegisterTransactionHeaders, h => {
          const val = get(o, h.value)
          data[h.text] = Number.isNaN(Number(val)) ? val : Number(val)
        })

        return data
      })
      .value()
  }

  const buildDepositTransactionsXlsxData = () => {
    return chain(cashRegisterTransactionsByType.value.deposit)
      .map(o => {
        const data = {}

        each(CashRegisterTransactionHeadersByType, h => {
          const val = get(o, h.value)
          data[h.text] = Number.isNaN(Number(val)) ? val : Number(val)
        })

        return data
      })
      .value()
  }

  const buildWithdrawTransactionsXlsxData = () => {
    return chain(cashRegisterTransactionsByType.value.withdraw)
      .map(o => {
        const data = {}

        each(CashRegisterTransactionHeadersByType, h => {
          const val = get(o, h.value)
          data[h.text] = Number.isNaN(Number(val)) ? val : Number(val)
        })

        return data
      })
      .value()
  }

  return {
    items,
    cashRegisterTransactionsByType,
    buildAllTransactionsXlsxData,
    buildDepositTransactionsXlsxData,
    buildWithdrawTransactionsXlsxData,
  }
}
