<template>
  <div>
    <interval-date-picker
      v-if="showDatePicker"
      :year="false"
      @date-updated="date = $event"
      @end-date-updated="endDate = $event"
      @interval-updated="interval = $event"
      @picked="
        getCashRegisterTransactions({ ...$event, ...$route.query});
        getCashRegisterTransactionSummaries({ ...$event, ...$route.query})"
    />

    <div class="w-full d-flex">
      <v-spacer />
      <v-btn
        :ripple="false"
        :loading="isLoading || isLoadingSummaries || isLoadingTx"
        :disabled="isLoading || isLoadingSummaries || isLoadingTx"
        color="secondary"
        small
        class="ma-2"
        @click="download"
      >
        <v-icon left>
          {{ icons.mdiFileExcel }}
        </v-icon>
        ダウンロード
      </v-btn>
    </div>

    <app-card-actions
      action-collapse
      class="mb-4"
    >
      <template #title>
        サマリ
      </template>

      <v-data-table
        id="cash-register-tx-summaries"
        :loading="isLoading || isLoadingSummaries"
        :headers="CashRegisterTransactionSummaryHeaders"
        :items="cashRegisterTransactionSummaries"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        sort-by="attributes.date"
        sort-desc
        no-data-text="データがありません"
      >
        <template #loading>
          <v-skeleton-loader
            height="50"
            type="table"
          />
        </template>

        <template #[`item.date`]="{item}">
          {{ item.date.replace(/-/g, '/') }}
        </template>

        <template #[`item.startBalance`]="{item}">
          {{ `¥${Number(item.startBalance).toLocaleString()}` }}
        </template>

        <template #[`item.depositAmount`]="{item}">
          {{ `¥${item.depositAmount.toLocaleString()}` }}
        </template>

        <template #[`item.withdrawAmount`]="{item}">
          {{ `¥${item.withdrawAmount.toLocaleString()}` }}
        </template>

        <template #[`item.todayDiff`]="{item}">
          <span :class="item.todayDiff >= 0 ? 'success--text' : 'error--text'">
            <span class="mr-1">
              {{ item.todayDiff >= 0 ? '+' : '-' }}
            </span>
            {{ `¥${Number(item.todayDiff).toLocaleString()}` }}
          </span>
        </template>

        <template #[`item.endBalance`]="{item}">
          {{ `¥${Number(item.endBalance).toLocaleString()}` }}
        </template>
      </v-data-table>
    </app-card-actions>

    <v-tabs
      v-model="currentTab"
      background-color="transparent"
      class="elevation-0"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.key"
      >
        {{ tab.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      :value="[0,1,2]"
      touchless
      multiple
      style="background: transparent"
    >
      <v-tab-item>
        <!-- 入出金all start -->
        <v-data-table
          v-show="currentTab === 0"
          id="cash-register-tx-all"
          :loading="isLoading || isLoadingTx"
          :headers="CashRegisterTransactionHeaders"
          :items="allCashRegisterTransactions"
          :header-props="{ sortByText: 'ソート' }"
          hide-default-footer
          disable-pagination
          no-data-text="データがありません"
        >
          <template #loading>
            <v-skeleton-loader type="table" />
          </template>

          <template #[`item.attributes.createdAt`]="{item}">
            <span class="text-no-wrap">{{ dateTime(item.attributes.createdAt) }}</span>
          </template>

          <template #[`item.attributes.amount`]="{item}">
            {{ `¥${item.attributes.amount.toLocaleString()}` }}
          </template>

          <template #[`item.txTypeText`]="{item}">
            <v-chip
              v-if="item.attributes.transactionType"
              v-bind="txTypeChipAttrs[item.attributes.transactionType]"
              small
            >
              {{ item.txTypeText }}
            </v-chip>
          </template>

          <template #[`item.from`]="{item}">
            <v-chip
              v-if="item.attributes.receiptId"
              small
              @click="getReceipt(item.attributes.receiptId)"
            >
              <clip-loader
                v-if="isLoadingReceipt && +receipt.id === +item.attributes.receiptId"
                :loading="true"
                :color="'#8A8D93'"
                size="16px"
                class="d-flex align-items pr-2"
              />
              {{ item.from }}
            </v-chip>

            <span v-else>
              {{ item.from }}
            </span>
          </template>
        </v-data-table>
      </v-tab-item>
      <!-- 入出金all start -->

      <!-- 入金 or 出金 start -->
      <v-tab-item
        v-for="o in [{ tabIdx: 1, key: 'deposit' }, { tabIdx: 2, key: 'withdraw' }]"
        :key="`cash-register-tx-${o.key}`"
      >
        <v-data-table
          v-show="currentTab === o.tabIdx"
          :id="`cash-register-tx-${o.key}`"
          :loading="isLoading || isLoadingTx"
          :headers="CashRegisterTransactionHeadersByType"
          :items="cashRegisterTransactionsByType[o.key]"
          :header-props="{ sortByText: 'ソート' }"
          hide-default-footer
          disable-pagination
          no-data-text="データがありません"
        >
          <template #loading>
            <v-skeleton-loader type="table" />
          </template>

          <template #[`item.attributes.createdAt`]="{item}">
            <span class="text-no-wrap">{{ dateTime(item.attributes.createdAt) }}</span>
          </template>

          <template #[`item.attributes.amount`]="{item}">
            {{ `¥${item.attributes.amount.toLocaleString()}` }}
          </template>

          <template #[`item.from`]="{item}">
            <v-chip
              v-if="item.attributes.receiptId"
              small
              @click="getReceipt(item.attributes.receiptId)"
            >
              <clip-loader
                v-if="isLoadingReceipt && +receipt.id === +item.attributes.receiptId"
                :loading="true"
                :color="'#8A8D93'"
                size="16px"
                class="d-flex align-items pr-2"
              />
              {{ item.from }}
            </v-chip>

            <span v-else>
              {{ item.from }}
            </span>
          </template>
        </v-data-table>
        <!-- 入金 or 出金 end -->
      </v-tab-item>
    </v-tabs-items>

    <receipt-show-drawer
      v-if="receipt && receipt.id && receipt.meta && receipt.attributes"
      :is-visible="!isLoadingReceipt && isReceiptOpen"
      :receipt-id="receipt.id"
      :table-name="receipt.meta.tableName"
      :start-at="receipt.meta.tableStartAt"
      :customer-count="receipt.meta.tableCustomerCount"
      :table-id="receipt.attributes.tableId"
      @close="closeReceipt"
      @deleted="deleteReceipt"
    />
  </div>
</template>

<script>
import {
  ref,
  provide,
  inject,
  getCurrentInstance,
} from '@vue/composition-api'
import { each } from 'lodash'
import { mdiFileExcel } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import CashRegisterTransactionApi from '@/api/waiter/CashRegisterTransaction'
import CashRegisterTransactionSummaryApi from '@/api/waiter/CashRegisterTransactionSummary'
import ReceiptApi from '@/api/waiter/Receipt'
import UserApi from '@/api/waiter/User'
import VendibleApi from '@/api/waiter/Vendible'
import useCompInit from '@/views/composable/useCompInit'
import useQueryString from '@/views/composable/useQueryString'
import useDateFormat from '@/views/composable/useDateFormat'
import useCashRegisterTransactionsDataTable from '@/views/composable/data-table/useCashRegisterTransactionsDataTable'
import useCashRegisterTransactionSummariesDataTable from '@/views/composable/data-table/useCashRegisterTransactionSummariesDataTable'
import IntervalDatePicker from '@/views/components/util/filter/IntervalDatePicker.vue'
import ReceiptShowDrawer from '@/views/components/receipt/ReceiptShowDrawer.vue'
import { CashRegisterTransactionSummaryHeaders, CashRegisterTransactionHeaders, CashRegisterTransactionHeadersByType } from '@/utils/constants/table-headers'

export default {
  components: {
    IntervalDatePicker,
    AppCardActions,
    ReceiptShowDrawer,
    ClipLoader,
  },
  props: {
    showDatePicker: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route } = useQueryString()
    const { isLoading, initWith } = useCompInit()
    const { dateTime } = useDateFormat()
    const currentClubMeta = inject('currentClubMeta', { today: new Date().toISOString().substr(0, 10) })
    const date = ref(route.value.query.date || currentClubMeta.today)
    const interval = ref(Number(route.value.query.interval) || 0)
    const endDate = ref(route.value.query.endDate)
    const currentTab = ref(0)
    const isLoadingTx = ref(false)
    const isLoadingSummaries = ref(false)
    const isLoadingReceipt = ref(false)
    const isReceiptOpen = ref(false)
    const isDownloading = ref(false)
    const cashRegisterTransactions = ref([])
    const cashRegisterTransactionSummaries = ref([])
    const receipt = ref({})
    const users = ref({})
    const vendibles = ref({})
    provide('receipt', receipt)
    provide('users', users)
    provide('vendibles', vendibles)

    const {
      items: allCashRegisterTransactions,
      cashRegisterTransactionsByType,
      buildAllTransactionsXlsxData,
      buildDepositTransactionsXlsxData,
      buildWithdrawTransactionsXlsxData,
    } = useCashRegisterTransactionsDataTable(cashRegisterTransactions)

    const { buildXlsxData: buildSummaryXlsxData } = useCashRegisterTransactionSummariesDataTable(cashRegisterTransactionSummaries)

    const download = () => {
      isDownloading.value = true

      const summariesData = buildSummaryXlsxData()
      const allTxData = buildAllTransactionsXlsxData()
      const depositTxData = buildDepositTransactionsXlsxData()
      const withdrawTxData = buildWithdrawTransactionsXlsxData()

      const sheets = [
        { name: 'サマリ', data: summariesData },
        { name: 'ALL', data: allTxData },
        { name: '入金', data: depositTxData },
        { name: '出金', data: withdrawTxData },
      ]

      const wb = XLSX.utils.book_new()
      each(sheets, sheet => {
        const data = XLSX.utils.json_to_sheet(sheet.data)
        XLSX.utils.book_append_sheet(wb, data, sheet.name)
      })

      let title = `${date.value}_${['日', '週', '月'][interval.value]}`
      if (endDate.value) title = `${date.value}~${endDate.value}`
      XLSX.writeFile(wb, `レジ_${title}.xlsx`)

      isDownloading.value = false
    }

    const getCashRegisterTransactions = async (params = null) => {
      isLoadingTx.value = true

      const res = await CashRegisterTransactionApi.getCashRegisterTransactions({ ...params })

      if (res?.status === 200) {
        cashRegisterTransactions.value = [...res.data.cashRegisterTransactions.data]
      }

      isLoadingTx.value = false
    }

    const getCashRegisterTransactionSummaries = async (params = null) => {
      isLoadingSummaries.value = true

      const res = await CashRegisterTransactionSummaryApi.getCashRegisterTransactionSummaries({ ...params })

      if (res?.status === 200) {
        cashRegisterTransactionSummaries.value = [...res.data.cashRegisterTransactionSummaries]
      }

      isLoadingSummaries.value = false
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers()

      if (res?.status === 200) {
        users.value = [...res.data.users.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.status === 200) {
        vendibles.value = [...res.data.vendibles.data]
      }
    }

    const getReceipt = async id => {
      receipt.value = { id }
      isLoadingReceipt.value = true

      const res = await ReceiptApi.getReceipt(id)

      if (res?.status === 200) {
        receipt.value = { ...res.data.receipt.data }

        isReceiptOpen.value = true
      }

      isLoadingReceipt.value = false
    }

    const closeReceipt = () => {
      isReceiptOpen.value = false
      receipt.value = {}
    }

    const deleteReceipt = () => {
      initWith([
        getCashRegisterTransactions({ ...route.value.query }),
        getCashRegisterTransactionSummaries({ ...route.value.query }),
      ])

      closeReceipt()

      vm.$toast.success('伝票を削除しました')
      vm.$toast.success('Txを削除しました')
    }

    initWith([
      getCashRegisterTransactions({ ...route.value.query }),
      getCashRegisterTransactionSummaries({ ...route.value.query }),
      getUsers(),
      getVendibles(),
    ])

    return {
      // data
      date,
      interval,
      endDate,
      currentTab,
      isLoading,
      isLoadingTx,
      isLoadingSummaries,
      isLoadingReceipt,
      isReceiptOpen,
      cashRegisterTransactions,
      cashRegisterTransactionSummaries,
      receipt,
      tabs: [
        { text: 'ALL', key: 'all' },
        { text: '入金', key: 'deposit' },
        { text: '出金', key: 'withdraw' },
      ],
      txTypeChipAttrs: {
        deposit: {
          color: 'success',
          class: 'v-chip-light-bg success--text',
        },
        withdraw: {
          color: 'error',
          class: 'v-chip-light-bg error--text',
        },
      },

      // computed
      allCashRegisterTransactions,
      cashRegisterTransactionsByType,
      CashRegisterTransactionSummaryHeaders,
      CashRegisterTransactionHeaders,
      CashRegisterTransactionHeadersByType,

      // methods
      getCashRegisterTransactions,
      getCashRegisterTransactionSummaries,
      getReceipt,
      closeReceipt,
      deleteReceipt,
      dateTime,
      download,

      icons: {
        mdiFileExcel,
      },
    }
  },
}
</script>
