<template>
  <v-dialog
    :value="isVisible"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
    persistent
    content-class="closing-dialog-card"
  >
    <v-app-bar
      v-if="isVisible"
      ref="header"
      outlined
      fixed
      flat
      style="z-index: 10"
    >
      <v-app-bar-nav-icon>
        <v-btn
          fab
          small
          :ripple="false"
          @click="close"
        >
          <v-icon>
            {{ icons.mdiDotsGrid }}
          </v-icon>
        </v-btn>
      </v-app-bar-nav-icon>

      <v-app-bar-title>
        <template #default>
          {{ tableName }} 伝票 No.{{ receiptId }}
        </template>
      </v-app-bar-title>
      <v-spacer></v-spacer>

      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        icon
        fab
        :ripple="false"
        :color="checkCompMode === 'check' ? 'primary' : ''"
        @click="clickCheckComp('check')"
      >
        <v-icon>{{ icons.mdiCurrencyJpy }}</v-icon>
      </v-btn>

      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        icon
        fab
        :ripple="false"
        :color="checkCompMode === 'history' ? 'primary' : ''"
        @click="clickCheckComp('history')"
      >
        <v-icon>
          {{ icons.mdiHistory }}
        </v-icon>
      </v-btn>
    </v-app-bar>

    <!-- eslint-disable vue/v-on-event-hyphenation -->
    <check
      v-if="receipt.attributes"
      :is-visible="isVisible"
      :header-height="headerHeight"
      :drawer-width="$vuetify.breakpoint.xsOnly ? '100%' : 400"
      :table-id="tableId"
      :is-check-drawer-open="isCheckDrawerOpen"
      :receipt="receipt"
      :table-name="tableName"
      :start-at="startAt"
      :customer-count="customerCount"
      @update:isCheckDrawerOpen="isCheckDrawerOpen = $event"
      @updated:receipt="updateReceipt($event)"
      @init:reset-func="resetFunc = $event"
      @init:edit-func="editFunc = $event"
      @deleted="$emit('deleted')"
    />
  </v-dialog>
</template>

<script>
import {
  ref,
  computed,
  inject,
  watch,
} from '@vue/composition-api'
import { mdiDotsGrid, mdiCurrencyJpy, mdiHistory } from '@mdi/js'
import { getVuetify, useRouter } from '@core/utils'
import { find } from 'lodash'
import ReceiptApi from '@/api/waiter/Receipt'
import Check from '@/views/components/table/TableShowDrawer/Check/Check.vue'

export default {
  components: {
    Check,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    receiptId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableName: {
      type: String,
      required: true,
      default: '',
    },
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    startAt: {
      type: String,
      required: true,
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props, { emit }) {
    // NOTE:
    // これを使う場合は親から以下の３つをprovideしてもらう必要がある
    // receipt, users, vendibles

    const $vuetify = getVuetify()

    const header = ref(null)
    const isCheckDrawerOpen = ref(true)
    const resetFunc = ref(() => {})
    const editFunc = ref(() => {})
    const checkCompMode = ref('check')

    const { route } = useRouter()

    const receipt = inject('receipt', {})

    const headerHeight = computed(() => {
      return header.value?.$el?.clientHeight || 46
    })

    const getReceipt = async () => {
      const res = await ReceiptApi.getReceipt(props.receiptId)

      if (res?.data) {
        receipt.value = res.data.receipt.data
      }
    }

    const updateReceipt = data => {
      receipt.value = data
      emit('updated', receipt.value)
    }

    const close = () => {
      emit('close')

      receipt.value = {}
      resetFunc.value()
    }

    const clickCheckComp = mode => {
      const func = {
        check: () => {
          checkCompMode.value = 'check'
          resetFunc.value()
          isCheckDrawerOpen.value = true
        },
        history: () => {
          checkCompMode.value = 'history'
          resetFunc.value()
          isCheckDrawerOpen.value = false
        },
      }

      func[mode]()
    }

    watch(() => props.isVisible, (newVal, _prevVal) => {
      if (newVal) {
        getReceipt().then(() => {
          if (route.value.query.vendingHistoryId) {
            checkCompMode.value = $vuetify.breakpoint.xsOnly ? 'history' : 'check'
            const targetVendingHistory = find(
              receipt.value.attributes?.vendingHistories?.data,
              o => Number(o.id) === Number(route.value.query.vendingHistoryId),
            )
            if (targetVendingHistory?.id) editFunc.value([targetVendingHistory])
          }
        })
      }
    })

    return {
      // data
      header,
      receipt,
      isCheckDrawerOpen,
      resetFunc,
      editFunc,
      checkCompMode,

      // computed
      headerHeight,

      // methods
      close,
      updateReceipt,
      clickCheckComp,

      icons: {
        mdiDotsGrid,
        mdiCurrencyJpy,
        mdiHistory,
      },
    }
  },
}
</script>
