import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getCashRegisterTransactionSummaries({ date = null, interval = null, endDate = null }) {
    return axiosIns.get(
      '/waiter/cash_register_transaction_summaries',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
}
