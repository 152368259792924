export const VendingHistoryListHeaders = [
  { text: '卓', value: 'meta.tableName' },
  { text: '名前', value: 'attributes.vendibleName', sortable: false },
  { text: '販売価格', value: 'attributes.sellingPrice', sortable: false },
  { text: 'TAX', value: 'attributes.taxPrice', sortable: false },
  { text: '受領者', value: 'recipients', sortable: false },
  { text: '売上計上先', value: 'contributors', sortable: false },
  { text: '作成日時', value: 'attributes.createdAt' },
  {
    text: '伝票',
    value: 'meta.receiptId',
  },
]
export const V2VendingHistoryListHeaders = [
  { text: '卓', value: 'meta.tableName' },
  { text: '名前', value: 'attributes.vendibleName', sortable: false },
  { text: '販売価格', value: 'attributes.sellingPrice', sortable: false },
  { text: 'TAX', value: 'attributes.taxPrice', sortable: false },
  { text: '受領者', value: 'meta.recipients', sortable: false },
  { text: '売上計上先', value: 'meta.contributors', sortable: false },
  { text: '作成日時', value: 'attributes.createdAt' },
  { text: '伝票', value: 'meta.receiptId' },
  { text: '商品タグ', value: 'meta.vendibleTagNames', sortable: false },
  { text: 'TAX種別', value: 'attributes.taxCharge', sortable: false },
]

export const BusinessResultDetailListHeaders = [
  { value: 'time', text: '営業日' },
  { value: 'cashAmount', text: '現金' },
  { value: 'cardAmount', text: 'カード' },
  { value: 'balanceReceivableAmount', text: '売掛' },
  { value: 'benefitAmount', text: '諸入' },
  { value: 'income', text: '収入計' },
  { value: 'castEarningAmount', text: 'キャスト給料' },
  { value: 'waiterEarningAmount', text: 'ボーイ給料' },
  { value: 'allianceEarningAmount', text: 'アライアンス給料' },
  { value: 'expenseAmount', text: '諸費' },
  { value: 'spending', text: '支出計' },
  { value: 'profit', text: '利益' },
]

export const BusinessResultMoneyDetailListHeaders = [
  { value: 'time', text: '営業日' },
  { value: 'cashAmount', text: '現金' },
  { value: 'balanceReceivableAmount', text: '売掛金回収' },
  { value: 'benefitAmount', text: '現金諸費' },
  { value: 'income', text: '現金収入計' },
  { value: 'castEarningAmount', text: 'キャスト日払' },
  { value: 'waiterEarningAmount', text: 'ボーイ日払' },
  { value: 'allianceEarningAmount', text: 'アライアンス日払' },
  { value: 'expenseAmount', text: '現金諸費' },
  { value: 'spending', text: '現金支出計' },
  { value: 'profit', text: '利益' },
  { value: 'realMoneyProfit', text: '利益(実態)' },
  { value: 'diff', text: '不明金' },
]

export const ExpenseAndBenefitListHeaders = [
  { value: 'businessDate', text: '営業日' },
  { value: 'primaryLabelName', text: '大ラベル', sortable: false },
  { value: 'secondaryLabelName', text: '小ラベル', sortable: false },
  { value: 'amount', text: '金額' },
  { value: 'isCash', text: '現金', sortable: false },
]

export const BalanceReceivableHeaders = [
  {
    text: '',
    value: 'meta.paid',
    sortable: false,
    width: '16px',
    align: 'center',
  },
  { text: '売掛金', value: 'attributes.amount' },
  { text: '回収金額', value: 'meta.paidAmount' },
  { text: '差額', value: 'diff' },
  { text: 'ノート', value: 'attributes.note', sortable: false },
  { text: '発生日時', value: 'attributes.createdAt' },
  { text: '伝票', value: 'attributes.receiptId' },
]

export const ReceiptHeaders = [
  { text: '卓', value: 'meta.tableName' },
  { text: '合計支払額', value: 'meta.realTotal' },
  { text: '小計', value: 'attributes.subtotal' },
  { text: 'TAX', value: 'attributes.serviceFee' },
  { text: '会計手数料', value: 'attributes.processingFee' },
  { text: '値引金額', value: 'attributes.discountFee' },
  { text: '合計お預かり金額', value: 'meta.totalCustomerPayAmount' },
  { text: 'お釣り', value: 'attributes.change' },
  { text: '発行日時', value: 'attributes.createdAt' },
  {
    text: 'No.',
    value: 'id',
  },
]

export const ReferringHistoriesHeaders = [
  { text: '卓', value: 'meta.tableName' },
  { text: '種別', value: 'attributes.referral.data.attributes.name' },
  { text: 'ユーザー', value: 'attributes.user.data.attributes.name' },
  { text: '作成日時', value: 'attributes.createdAt' },
  { text: '伝票', value: 'meta.receiptId' },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end',
  },
]

export const TimecardHeaders = [
  { text: '日付', value: 'meta.date' },
  { text: 'ユーザー', value: 'meta.userName' },
  { text: '出勤時刻', value: 'attributes.startAt' },
  { text: '退勤時刻', value: 'attributes.leaveAt' },
  { text: '休憩', value: 'attributes.breakCards.data' },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end',
  },
]

export const CashRegisterTransactionSummaryHeaders = [
  { text: '日付', value: 'date' },
  { text: '開始時点レジ内金額', value: 'startBalance' },
  { text: '入金合計', value: 'depositAmount' },
  { text: '出金合計', value: 'withdrawAmount' },
  { text: '変動', value: 'todayDiff' },
  { text: '終了時点レジ内金額', value: 'endBalance' },
]

export const CashRegisterTransactionHeaders = [
  { text: '実行日時', value: 'attributes.createdAt' },
  { text: '金額', value: 'attributes.amount' },
  { text: 'Txタイプ', value: 'txTypeText' },
  { text: '実行者', value: 'meta.userName', sortable: false },
  { text: '摘要', value: 'from', sortable: false },
]

export const CashRegisterTransactionHeadersByType = [
  { text: '実行日時', value: 'attributes.createdAt' },
  { text: '金額', value: 'attributes.amount' },
  { text: '実行者', value: 'meta.userName', sortable: false },
  { text: '摘要', value: 'from', sortable: false },
]
