import { chain, each, get } from 'lodash'
import { CashRegisterTransactionSummaryHeaders } from '@/utils/constants/table-headers'

export default cashRegisterTransactionSummaries => {
  const buildXlsxData = () => {
    return chain(cashRegisterTransactionSummaries.value)
      .map(o => {
        const data = {}

        each(CashRegisterTransactionSummaryHeaders, h => {
          const val = get(o, h.value)
          data[h.text] = Number.isNaN(Number(val)) ? val : Number(val)
        })

        return data
      })
      .value()
  }

  return {
    buildXlsxData,
  }
}
