var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showDatePicker)?_c('interval-date-picker',{attrs:{"year":false},on:{"date-updated":function($event){_vm.date = $event},"end-date-updated":function($event){_vm.endDate = $event},"interval-updated":function($event){_vm.interval = $event},"picked":function($event){_vm.getCashRegisterTransactions(Object.assign({}, $event, _vm.$route.query));
      _vm.getCashRegisterTransactionSummaries(Object.assign({}, $event, _vm.$route.query))}}}):_vm._e(),_c('div',{staticClass:"w-full d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"ripple":false,"loading":_vm.isLoading || _vm.isLoadingSummaries || _vm.isLoadingTx,"disabled":_vm.isLoading || _vm.isLoadingSummaries || _vm.isLoadingTx,"color":"secondary","small":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1),_c('app-card-actions',{staticClass:"mb-4",attrs:{"action-collapse":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" サマリ ")]},proxy:true}])},[_c('v-data-table',{attrs:{"id":"cash-register-tx-summaries","loading":_vm.isLoading || _vm.isLoadingSummaries,"headers":_vm.CashRegisterTransactionSummaryHeaders,"items":_vm.cashRegisterTransactionSummaries,"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","sort-by":"attributes.date","sort-desc":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"height":"50","type":"table"}})]},proxy:true},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.replace(/-/g, '/'))+" ")]}},{key:"item.startBalance",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (Number(item.startBalance).toLocaleString())))+" ")]}},{key:"item.depositAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (item.depositAmount.toLocaleString())))+" ")]}},{key:"item.withdrawAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (item.withdrawAmount.toLocaleString())))+" ")]}},{key:"item.todayDiff",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.todayDiff >= 0 ? 'success--text' : 'error--text'},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.todayDiff >= 0 ? '+' : '-')+" ")]),_vm._v(" "+_vm._s(("¥" + (Number(item.todayDiff).toLocaleString())))+" ")])]}},{key:"item.endBalance",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (Number(item.endBalance).toLocaleString())))+" ")]}}],null,true)})],1),_c('v-tabs',{staticClass:"elevation-0",attrs:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.key},[_vm._v(" "+_vm._s(tab.text)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"value":[0,1,2],"touchless":"","multiple":""}},[_c('v-tab-item',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 0),expression:"currentTab === 0"}],attrs:{"id":"cash-register-tx-all","loading":_vm.isLoading || _vm.isLoadingTx,"headers":_vm.CashRegisterTransactionHeaders,"items":_vm.allCashRegisterTransactions,"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"item.attributes.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dateTime(item.attributes.createdAt)))])]}},{key:"item.attributes.amount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (item.attributes.amount.toLocaleString())))+" ")]}},{key:"item.txTypeText",fn:function(ref){
      var item = ref.item;
return [(item.attributes.transactionType)?_c('v-chip',_vm._b({attrs:{"small":""}},'v-chip',_vm.txTypeChipAttrs[item.attributes.transactionType],false),[_vm._v(" "+_vm._s(item.txTypeText)+" ")]):_vm._e()]}},{key:"item.from",fn:function(ref){
      var item = ref.item;
return [(item.attributes.receiptId)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.getReceipt(item.attributes.receiptId)}}},[(_vm.isLoadingReceipt && +_vm.receipt.id === +item.attributes.receiptId)?_c('clip-loader',{staticClass:"d-flex align-items pr-2",attrs:{"loading":true,"color":'#8A8D93',"size":"16px"}}):_vm._e(),_vm._v(" "+_vm._s(item.from)+" ")],1):_c('span',[_vm._v(" "+_vm._s(item.from)+" ")])]}}],null,true)})],1),_vm._l(([{ tabIdx: 1, key: 'deposit' }, { tabIdx: 2, key: 'withdraw' }]),function(o){return _c('v-tab-item',{key:("cash-register-tx-" + (o.key))},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === o.tabIdx),expression:"currentTab === o.tabIdx"}],attrs:{"id":("cash-register-tx-" + (o.key)),"loading":_vm.isLoading || _vm.isLoadingTx,"headers":_vm.CashRegisterTransactionHeadersByType,"items":_vm.cashRegisterTransactionsByType[o.key],"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"item.attributes.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dateTime(item.attributes.createdAt)))])]}},{key:"item.attributes.amount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (item.attributes.amount.toLocaleString())))+" ")]}},{key:"item.from",fn:function(ref){
      var item = ref.item;
return [(item.attributes.receiptId)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.getReceipt(item.attributes.receiptId)}}},[(_vm.isLoadingReceipt && +_vm.receipt.id === +item.attributes.receiptId)?_c('clip-loader',{staticClass:"d-flex align-items pr-2",attrs:{"loading":true,"color":'#8A8D93',"size":"16px"}}):_vm._e(),_vm._v(" "+_vm._s(item.from)+" ")],1):_c('span',[_vm._v(" "+_vm._s(item.from)+" ")])]}}],null,true)})],1)})],2),(_vm.receipt && _vm.receipt.id && _vm.receipt.meta && _vm.receipt.attributes)?_c('receipt-show-drawer',{attrs:{"is-visible":!_vm.isLoadingReceipt && _vm.isReceiptOpen,"receipt-id":_vm.receipt.id,"table-name":_vm.receipt.meta.tableName,"start-at":_vm.receipt.meta.tableStartAt,"customer-count":_vm.receipt.meta.tableCustomerCount,"table-id":_vm.receipt.attributes.tableId},on:{"close":_vm.closeReceipt,"deleted":_vm.deleteReceipt}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }